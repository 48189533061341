<template>
    <v-card flat>
        <v-card-text class="pa-0">
            <v-alert prominent type="info" outlined>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">Instance Creation with Latest Shared Content</span>
                    The instance(s) you will create will contain the most recent contents that you have shared so far (if any).
                </div>
            </v-alert>
            <v-tabs v-model="instanceInvitationMode" color="primary">
                <v-tab v-if="!isSpaceArchived">Invite via Link</v-tab>
                <v-tab>Invite via Email</v-tab>
                <v-tab-item v-if="!isSpaceArchived">
                    <div v-if="!invalidGenerator" class="mt-10">
                        <v-form v-if="instanceInvitationMode === instanceInvitationTabOptions.INVITE_VIA_LINK">
                            <h3 class="secondary--text mt-4">Share the following link (e.g. via a Learning Management System)</h3>
                            <div class="my-2">Participants will be able to sign up using the below link and receive their own separate instance for work</div>
                            <div class="d-flex justify-center align-center">
                                <v-text-field :value="firstInvGen" :readonly="true" outlined dense></v-text-field>
                                <copy-to-clipboard
                                    :textToCopy="firstInvGen"
                                    buttonClass="caption font-weight-bold"
                                    buttonColor="primary"
                                    buttonName="copy to clipboard"
                                    :iconButton="true"
                                    :isSmall="false" />
                            </div>
                        </v-form>
                    </div>
                    <div v-else>
                        <v-banner two-line class="mt-5">
                            <v-avatar slot="icon" color="white" size="40">
                                <v-icon x-large icon="info" color="info">info</v-icon>
                            </v-avatar>
                            <span class="subtitle-1">No valid invitaiton generator were found. Please create a new generator to invite users.</span>
                            <template v-slot:actions>
                                <v-btn :loading="creatingGenerator" @click="createInvitationGenerator" text color="primary">create invitation generator</v-btn>
                            </template>
                        </v-banner>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="mt-10">
                        <v-form ref="form" v-model="validMultipleInstances">
                            <h4 class="secondary--text">Email addresses</h4>
                            <v-textarea v-model="emailsInput" required @input="updateEmailString" rows="3" auto-grow :disabled="inviting" outlined>
                                <template v-slot:label>Enter user emails</template>
                            </v-textarea>
                        </v-form>

                        <v-dialog persistent v-model="showEmailWarning" max-width="500">
                            <v-card>
                                <v-card-title><span class="title secondary--text">Too many recipients</span></v-card-title>
                                <v-card-text>
                                    <p>You might want to consider inviting this many users via the invitation link instead:</p>
                                    <div class="d-flex justify-center align-center">
                                        <v-text-field :value="firstInvGen" :readonly="true" outlined></v-text-field>
                                        <CopyToClipboard
                                            :textToCopy="firstInvGen"
                                            buttonClass="caption font-weight-bold"
                                            buttonColor="primary"
                                            :isTextButton="true"
                                            buttonName="copy to clipboard"
                                            :iconButton="true"
                                            :isSmall="false" />
                                    </div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="closeDialog" text>Don't invite</v-btn>
                                    <v-btn @click="skipDialog" color="primary" text>Invite anyway</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <div v-if="emailsInput.length" class="mt-3 secondary--text">
                            <h3>A separate instance will be created for each of the following users who will receive an invitation email to join:</h3>
                            <div>
                                <ul v-for="(email, index) in emails" :key="index" class="mt-3">
                                    <li
                                        v-if="
                                            email.status === emailValidationOutcomes.LOW_RISK_EMAIL ||
                                            email.status === emailValidationOutcomes.UNKNOWN_RISK_EMAIL
                                        ">
                                        {{ email.email }}
                                        <v-chip class="ml-1" x-small color="success">
                                            <v-icon small class="mr-1">check_circle</v-icon>
                                            <span class="text-uppercase font-weight-bold">validated</span>
                                        </v-chip>
                                    </li>
                                    <li v-else-if="email.status === emailValidationOutcomes.HIGH_RISK_EMAIL">
                                        {{ email.email }}
                                        <v-chip class="ml-1" x-small color="error">
                                            <v-icon small class="mr-1">cancel</v-icon>
                                            <span class="text-uppercase font-weight-bold">validation failed</span>
                                        </v-chip>
                                    </li>
                                    <li v-else-if="email.status === emailValidationOutcomes.UNVALIDATED_EMAIL">
                                        {{ email.email }}
                                        <v-chip class="ml-1" x-small color="secondary">
                                            <v-icon small class="mr-1">warning</v-icon>
                                            <span class="text-uppercase font-weight-bold">validation error</span>
                                        </v-chip>
                                    </li>
                                    <li v-else-if="validatingEmails">
                                        {{ email.email }}
                                        <v-progress-circular :size="20" class="ml-1" indeterminate color="primary" />
                                    </li>
                                    <li v-else>{{ email.email }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs>
            <InvitationErrorsDialog
                :showDialog="showInvitationErrorsDialog"
                :errorType="validationErrorType"
                :invalidEmails="invalidEmailsList"
                @proceedWithTheInvitation="inviteUsers($event.value)"
                @inviteWithoutFetchingDistributed="createInstances(null, $event.value)"
                @fetchAndInviteAgain="inviteUsers($event.value)" />
        </v-card-text>
        <v-card-actions v-if="instanceInvitationMode !== instanceInvitationTabOptions.INVITE_VIA_LINK">
            <div class="d-flex flex-column align-center w-100">
                <v-checkbox v-if="$store.state.userInfo.is_sysadmin" v-model="generateInvitationLinksOnly" class="mb-1 mt-5 pa-0">
                    <template v-slot:label>
                        <div class="d-flex align-center">
                            Generate invitations but don't send emails
                            <v-chip class="ml-1" color="info" label outlined x-small>Sysadmin</v-chip>
                        </div>
                    </template>
                </v-checkbox>
                <v-btn
                    @click="validateEmailsAndInvite()"
                    :loading="inviting || validatingEmails"
                    :disabled="!validMultipleInstances || validatingEmails || inviting || !emailsInput"
                    color="primary"
                    class="mr-1">
                    <v-icon small>add</v-icon>
                    invite
                </v-btn>
                <v-alert v-if="error" color="error" icon="warning" class="mt-4 w-100" text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">Invitation Failure Error</span>
                        An error has occurred while inviting the users. Please try again and if the problem persists contact support@alphacruncher.com
                    </div>
                </v-alert>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { splitEmails, sortArray, isDateGreaterThanToday, isCurrentState } from '@/utils'
import { emailValidation } from '@/mixins/validateEmail'
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')
export default {
    mixins: [enumsData, emailValidation],
    data() {
        return {
            instanceInvitationMode: 0,
            instanceInvitationTabOptions: {
                INVITE_VIA_EMAIL: 1,
                INVITE_VIA_LINK: 0
            },
            error: false,
            errorContent: 'An error has occurred',
            emailsInput: '',
            emailsData: [],
            emailList: [],
            failedInvitations: [],
            inviting: false,
            validMultipleInstances: false,
            invgens: [],
            distributedFetchingError: false,
            DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR: 'fetchingDistributedError',
            generateInvitationLinksOnly: false,
            creatingGenerator: false,
            invalidGenerator: false,
            showEmailWarning: false
        }
    },
    components: {
        CopyToClipboard,
        InvitationErrorsDialog
    },
    methods: {
        updateEmailString: function () {
            this.validatedEmails = []
            const emails = splitEmails(this.emailsInput)
            this.emailsData = emails
            this.emailList = emails.map(email => email.email)
        },
        createInstances: function (latestDistributedSnapshotId, confirmed) {
            if (confirmed) {
                this.inviting = true
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Creating instance, please wait...',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                this.error = false
                const postBody = { generate_link_only: this.generateInvitationLinksOnly }
                let apiURL = ''
                const emailsString = this.emailList.join(',')
                if (this.emailsInput) {
                    apiURL = '/spaces/' + this.$route.params.sid + '/create_individual_instances_async'
                    postBody.emails = emailsString
                }
                if (latestDistributedSnapshotId !== null) {
                    postBody.from_snid = latestDistributedSnapshotId
                }

                this.$axios
                    .post(apiURL, postBody)
                    .then(response => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Instance creation has been started. Invited users will receive an email to accept the invitation.',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'check_circle'
                        })
                        this.emailsInput = ''
                        this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
                        this.$store.dispatch('spaceStore/fetchSpaceInvitations', this.$route.params.sid)
                    })
                    .catch(error => {
                        this.error = true
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.reason &&
                            error.response.data.reason[0].includes('duplicate key value violates unique constraint "uc_sid_short"')
                        ) {
                            this.errorContent = 'Another instance uses the short name you chose for this instance, please choose another name'
                        } else if (
                            error.response &&
                            error.response.data &&
                            error.response.data.reason &&
                            error.response.data.reason[0].includes('duplicate key value violates unique constraint "uc_sid_long"')
                        ) {
                            this.errorContent =
                                'An instance associated with ' +
                                error.response.data.reason[0].match(/\d,.*/g)[0].replace(' already exists.', '').slice(3).replace(')', '') +
                                ' exists already'
                        } else {
                            this.$store.dispatch('showSnackBar', {
                                snackBarText:
                                    'An error has occurred while sending the invitations, please try again and if the problem persists contact support@alphacruncher.com.',
                                snackBarTimeout: 10000,
                                snackBarIcon: 'check_circle'
                            })
                        }
                    })
                    .finally(() => {
                        this.inviting = false
                        this.generateInvitationLinksOnly = false
                    })
            }
        },
        inviteUsers: function (confirmed) {
            this.setEmailValidationDefaultData()
            this.inviting = true
            this.distributedFetchingError = false
            if (confirmed && this.distributedInstanceId) {
                this.$axios
                    .get(`instances/${this.distributedInstanceId}/snapshots`)
                    .then(response => {
                        if (response && response.data && response.data.length) {
                            const distributedSnapshots = response.data.filter(snapshot => !isCurrentState(snapshot.short_id))
                            const sortedSnapshots = sortArray(distributedSnapshots, 'snid', 'descending', false)
                            if (sortedSnapshots.length) {
                                const latestSnapshotId = sortedSnapshots[0].snid
                                this.createInstances(latestSnapshotId, true)
                            } else {
                                this.createInstances(null, true)
                            }
                        } else {
                            this.createInstances(null, true)
                        }
                    })
                    .catch(() => {
                        this.distributedFetchingError = true
                    })
                    .finally(() => {
                        this.inviting = false
                    })
            } else if (confirmed) {
                this.createInstances(null, true)
            }
        },
        createInvitationGenerator: function () {
            this.creatingGenerator = true
            const validityDateTodayPlusThreeMonths = new Date()
            validityDateTodayPlusThreeMonths.setMonth(validityDateTodayPlusThreeMonths.getMonth() + 3)
            // BE expects date format to have always 0 milliseconds
            validityDateTodayPlusThreeMonths.setMilliseconds(0)
            const validityDate = validityDateTodayPlusThreeMonths.toISOString()
            this.$axios
                .post('/invitations/create_single_instance_generator', {
                    sid: this.$route.params.sid,
                    init_name: 'LAST_IMMUTABLE_DISTRIBUTED_SNAPSHOT',
                    validity_timestamp: validityDate
                })
                .then(response => {
                    if (response.data.is_valid) {
                        this.invalidGenerator = false
                        this.invgens = response.data
                    }
                    if (!response.data.is_valid && !isDateGreaterThanToday(response.data.validity_timestamp)) {
                        this.invalidGenerator = true
                        this.errorContent = 'This invitation generator has expired. Please contact your lecturer or TA to get a valid invitation'
                    } else if (!response.data.is_valid) {
                        this.invalidGenerator = true
                        this.errorContent = 'This invitation generator has been exhausted. Please contact your lecturer or TA to get an invitation'
                    }
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Could not create invitation generator, please try again later.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.creatingGenerator = false
                })
        },
        validateEmailsAndInvite: function () {
            if (this.emailsInput.split('@').length - 1 > 10) {
                this.showEmailWarning = true
            } else {
                this.skipDialog()
            }
        },
        closeDialog() {
            this.showEmailWarning = false
        },
        skipDialog() {
            this.showEmailWarning = false
            this.distributedFetchingError = false
            this.validateEmails(this.emailList).finally(() => {
                if (!this.emailsValidationError && !this.backendError && !this.foundInvalidEmails) {
                    this.inviteUsers(true)
                }
            })
        }
    },
    computed: {
        ...mapState('spaceStore', ['distributedInstanceId']),
        ...mapGetters('spaceStore', ['currentSpaceType', 'isSpaceArchived']),
        showInvitationErrorsDialog() {
            if (this.foundInvalidEmails || this.backendError || this.emailsValidationError || this.distributedFetchingError) {
                return true
            }
            return false
        },
        validationErrorType() {
            if (this.distributedFetchingError) {
                return this.DISTRIBUTED_SNAPSHOTS_FETCHING_ERROR
            } else if (this.foundInvalidEmails) {
                return this.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.backendError || this.emailsValidationError) {
                return this.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        },
        firstInvGen() {
            if (this.invgens) {
                return document.location.origin + this.$router.resolve({ name: 'signup-index', params: { space: 'class', token: this.invgens.token } }).href
            } else {
                return 'No Invitation URL for this space'
            }
        },
        emails: function () {
            if (this.validatedEmails.length) {
                return this.validatedEmails
            } else if (this.emailsData.length) {
                return this.emailsData
            }
            return []
        }
    },
    mounted() {
        this.$axios.post(`/spaces/${this.$route.params.sid}/invgens`, { invitation_generator_type: 'SINGLE_INSTANCE' }).then(response => {
            const sortedGenerators = sortArray(response.data, 'igid', 'descending', false)
            if (sortedGenerators.length && sortedGenerators[0].is_valid) {
                this.invgens = sortedGenerators[0]
            } else if (sortedGenerators.length && !sortedGenerators[0].is_valid && !isDateGreaterThanToday(sortedGenerators[0].validity_timestamp)) {
                this.invalidGenerator = true
                this.errorContent = 'This invitation generator has expired. Please contact your lecturer or TA to get a valid invitation'
            } else if (sortedGenerators.length && !sortedGenerators[0].is_valid) {
                this.invalidGenerator = true
                this.errorContent = 'This invitation generator has been exhausted. Please contact your lecturer or TA to get an invitation'
            }
        })
    }
}
</script>
